import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material'; 
import NewLogin from './newLogin'
function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }
export default function AlignItemsList({db, user}) {
    const [logins, setLogin] = React.useState([])
    const [dialog, setDialog]=React.useState(false)
    const [dialogData, setDialogData]=React.useState({})
    const getData= async ()=>{
      var news=[]
      const newsRef = db.collection("login");
      newsRef.get().then(querySnapshot=>{
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var temp=doc.data()
          temp['id']=doc.id
          news.push(temp)
          console.log(doc.id, " => ", doc.data());
        });
        setLogin(news)
      })
      
    }
    React.useEffect(()=>{
      getData()
    }, [db])

    const dialogToggle=(value, data, i)=>{
      if(value){
        setDialog(value)
        if(i){
          data['index']=i
        }
        setDialogData(data)

      }else{
        setDialog(value)
        setDialogData({})
      }
        
    }

    const deleteData=()=>{
      if(!dialogData.id){
        return
      }
      if(!(user.delete&&user.delete.includes('login'))){
        return
      }
      db.collection("login").doc(dialogData.id).delete().then(() => {
          getData()
          alert("Login successfully deleted!");
          dialogToggle(false, {})
      }).catch((error) => {
          alert("Error removing Logins");
      });
    }

    const editData=(email, read, write, delete1, name)=>{
      if(!(user.write&&user.write.includes('login'))){
        return
      }
      db.collection("login").doc(dialogData.id).set({
       email:email,
       read:read,
       write:write,
       delete:delete1,
       name:name
      })
      .then((docRef) => {
        alert("Edited Successfully")
        getData()
        dialogToggle(false, {})

      })
      .catch((error) => {
        alert("Error Updating, Please try again")
      });
    }

    const addData=(email, read, write, delete1, name)=>{
      if(!(user.write&&user.write.includes('login'))){
        return
      }
      db.collection("login").doc(email).set({
        email:email,
        read:read,
        write:write,
        delete:delete1,
        name:name
      })
      .then((docRef) => {
        alert("Added Successfully")
        getData()
        dialogToggle(false, {})

      })
      .catch((error) => {
        alert("Error Updating, Please try again")
          console.error("Error adding document: ", error);
      });

    }
  return (
    <List sx={{ width: '100%', maxWidth: 1500, bgcolor: 'background.paper' }}>
     {logins.map((item,i)=>{
       return(  <ListItem alignItems="flex-start"
            secondaryAction={
                <IconButton edge="end" aria-label="edit" onClick={()=>dialogToggle(true, item, i)}
                disabled={!(user.write&&user.write.includes('login'))}
                
                >
                    <EditIcon />
                </IconButton>
          }
          >
       <ListItemAvatar>
       <Avatar alt={i+1} {...stringAvatar(item.name +` ${Math.random().toString()}`)} >{i+1}</Avatar>
       </ListItemAvatar>
       <ListItemText
         primary={(item.name?item.name:"") + ", "+item.email}
         secondary={
           <React.Fragment>
             <Typography
               sx={{ display: 'inline' }}
               component="span"
               variant="body2"
               color="text.primary"
             >
             </Typography>
             Read:{item.read.join(", ")}<br/>
             Write:{item.write.join(", ")}<br/>
             Delete:{item.delete.join(", ")}
           </React.Fragment>
         }
       />
       <Divider variant="inset" component="li" />
     </ListItem>)
     })}
     
    
  
     <NewLogin db={db} data={dialogData} setOpen={dialogToggle} open={dialog} editData={editData} addData={addData} deleteData={deleteData}/>
    </List>
  );
}
