import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Dialog from './gallaryDialog'

export default function TitlebarImageList({db, user}) {
  const [gallary, setGallery]=React.useState([])
  const [dialog, setDialog]=React.useState(false)
  const [load, setLoad]=React.useState(false)
  const [dialogData, setDialogData]=React.useState({})
  const getData= async ()=>{
    var news=[]
    const newsRef = db.collection("gallary");
    newsRef.get().then(querySnapshot=>{
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        var temp=doc.data()
        temp['id']=doc.id
        news.push(temp)
        setGallery(news)
        console.log(doc.id, " => ", doc.data());
      });
    })
    
  }
  React.useEffect(()=>{
    getData()
  }, [db])

  const dialogToggle=(value, data, i)=>{
    if(value){
      setDialog(value)
      if(i){
        data['index']=i
      }
      setDialogData(data)

    }else{
      setDialog(value)
      setDialogData({})
    }
      
  }

  return (
    <ImageList sx={{ width: 600, margin:"auto" }}>
      <ImageListItem key="Subheader" cols={3}>
        <ListSubheader component="div">Galary</ListSubheader>
      </ImageListItem>
      {gallary.map((item, i) => (
        <ImageListItem key={item.img}>
          <img
            src={`${item.image}`}
            srcSet={`${item.image}`}
            alt={item.title}
            loading="lazy"
          />
          <ImageListItemBar
            title={item.title}
            subtitle={item.author}
            actionIcon={
              <IconButton
                onClick={()=>dialogToggle(true, item, i)}
                sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                aria-label={`info about ${item.title}`}
              >
                <InfoIcon />
              </IconButton>
            }
          />
        </ImageListItem>
      ))}
      <Dialog db={db} getData={getData} open={dialog} setOpen={dialogToggle} data={dialogData} user={user}/>
    </ImageList>
  );
}
