import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItem } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function FormDialog({open, setOpen, data, editData, addData, deleteData}) {
  const [email, setEmail]=React.useState(data.email)
  const [read, setRead]=React.useState(data.read)
  const [write, setWrite]=React.useState(data.write)
  const [delete1, setDelete] =React.useState(data.delete)
  const [news, setNews]=React.useState([])  
  const [name, setName]=React.useState(data.name)

  const handleClickOpen = () => {
    setOpen(true,{});
  };

  const handleClose = () => {
    setOpen(false);
    setName("")

  };

  const submit=()=>{
    if((data.email!==email || data.name!==name || data.read!==read || data.write!==write || data.delete!==delete1)&&email!==""){
      if(data.email){
        editData(email, read, write, delete1, name)
      } else{
        addData(email, read, write, delete1, name)
      }
    }else{
      alert("Check Fields")
    }
  }

  React.useEffect(()=>{
    setEmail(data.email)
  }, [data.email])

  React.useEffect(()=>{
    setName(data.name)
  }, [data.name])
  
  React.useEffect(()=>{
    if(data.read)  setRead(data.read)
    else setRead([])
  }, [data.read])
  
  React.useEffect(()=>{
    if(data.write)  setWrite(data.write)
    else setWrite([])
  }, [data.write])
  
  React.useEffect(()=>{
    if(data.delete)  setDelete(data.delete)
    else setDelete([])
  }, [data.delete])
  
  const handleReadChange = (event, type) => {
    var d = [...read]
    if(event.target.checked){ 
      d.push(type)
    }
    else{
      
      d = d.filter(item => item !== type)
    }
    setRead(d)
  };
    
  const handleWriteChange = (event, type) => {
    var d = [...write]
    if(event.target.checked){ 
      d.push(type)
    }
    else{
      
      d = d.filter(item => item !== type)
    }
    setWrite(d)
  };
    
  const handleDeleteChange = (event, type) => {
    var d = [...delete1]
    if(event.target.checked){ 
      d.push(type)
    }
    else{
      
      d = d.filter(item => item !== type)
    }
    setDelete(d)
  };
 
  return (
    <div style={{display:"flex", justifyContent:"center"}}>
      <Button variant="outlined" sx={{marginTop:10}} onClick={handleClickOpen}>
        Add Login
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{data.email?`Edit - ${data.name}`:"Add Login"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e)=>{setEmail(e.target.value)}}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e)=>{setName(e.target.value)}}
            variant="standard"
          />
          <div >
            <p style={{width:80, display:"inline-block"}}>News: </p>
          
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleReadChange(e, 'news')}} checked={read&&read.includes("news")}/>} label="Read" />
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleWriteChange(e, 'news')}} checked={write&&write.includes("news")}/>} label="Write" />
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleDeleteChange(e, 'news')}} checked={delete1&&delete1.includes("news")} />} label="Delete" />
          </div>
          <div>
            <p style={{width:80, display:"inline-block"}}>Gallary: </p>
          
          
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleReadChange(e, 'gallary')}} checked={read&&read.includes("gallary")}/>} label="Read" />
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleWriteChange(e, 'gallary')}} checked={write&&write.includes("gallary")}/>} label="Write" />
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleDeleteChange(e, 'gallary')}} checked={delete1&&delete1.includes("gallary")} />} label="Delete" />
          </div>
          <div>
            <p style={{width:80, display:"inline-block"}}>Login: </p>
          
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleReadChange(e, 'login')}} checked={read&&read.includes("login")}/>} label="Read" />
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleWriteChange(e, 'login')}} checked={write&&write.includes("login")}/>} label="Write" />
          <FormControlLabel control={<Checkbox onChange={(e)=>{handleDeleteChange(e, 'login')}} checked={delete1&&delete1.includes("login")} />} label="Delete" />

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {data.name?<Button onClick={deleteData}>Delete</Button>:""} 
          <Button onClick={submit}>{data.name?`Edit`:"Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
