import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
import firebase from 'firebase'
import logo from '../../../media/logo.png'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn({db, setUser}) {
  let history = useHistory();
  const [route, setRoute]= React.useState("login")
  

  const handleSubmit=(event)=>{
    if(route==='login'){
      handleLogin(event)
    }
    else{
      handleRegister(event)
    }
  }

  const handleLogin = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
   
    firebase.auth().signInWithEmailAndPassword(data.get('email'), data.get('password'))
    .then((userCredential) => {
      // Signed in 
      var user = userCredential.user;
      db.collection("login").doc(data.get('email').toLowerCase())
      .get().then(doc=>{
        if(doc.exists){
            setUser(doc.data())
            history.push('/home')
        }else{
          alert("Check your credentials")
        }
      })

      // ...
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      alert("Error "+error.message)
      // ..
    });

  };

  const handleRegister = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    db.collection("login").doc(data.get('email').toLowerCase())
    .get().then(doc=>{
      if(doc.exists){
        firebase.auth().createUserWithEmailAndPassword(data.get('email'), data.get('password'))
        .then((userCredential) => {
          // Signed in 
          var user = userCredential.user;
          setUser(doc.data())
          history.push('/home')
          
      // ...
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      alert("Error "+error.message)
      // ..
    });
      }else{
        alert("Check your credentials or Contact Admin")
      }
    })
    

  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            
            <img src={logo} width={55} style={{margin:3}}/>
          <Typography component="h1" variant="h5">
            {route==="login"?"Login":'Register'}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
             {route==="login"?" Login":'Register'}
            </Button>
            <Grid container>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="#" variant="body2" onClick={()=>setRoute(route==="login"?'register':'login')}>
                  {route==="login"?"Don't have an account? Register":"Already have an account? Login"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}