import React  from 'react';
import './App.css';
import {
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import Login from './component/routes/login'
import Home from './component/routes/home'
import firebase from "firebase";
require("firebase/app")
// Required for side-effects
require("firebase/firestore");
 firebase.initializeApp({
  apiKey: "AIzaSyBRMltXmEPu9b41THcp0KDuXDsAVyEyHBY",
  authDomain: "school-3712f.firebaseapp.com",
  projectId: "school-3712f",
  storageBucket: "school-3712f.appspot.com",
  messagingSenderId: "2085476652",
  appId: "1:2085476652:web:2edd2c172c435e7254f489",
  measurementId: "G-5L8JYRREBB"
});

var db = firebase.firestore();
function App() {
  let history = useHistory();
  const [user, setUser] =React.useState({})
  
  React.useEffect(() => {
    if(!window.location.href.includes("login")&&!user.email){
      history.push('/login')
    }
    if(window.location.href.includes("login")&&user.email){
      history.push('/home')
    }
    
  }, [user])
  return (
    <div className="">
      <Switch>
          <Route path="/login">
            <Login db={db} user={user} setUser={setUser}/>
          </Route>
          <Route path="/home">
            <Home db={db} user={user}/>
          </Route>
         
        </Switch>
    </div>
  );
}

export default App;
