import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import logo from '../../../../media/logo.png'
import { useHistory } from 'react-router';
import firebase from 'firebase';
export default function ButtonAppBar() {
  let history = useHistory()
  const logout=()=>{
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      history.push('/login')
      

    }).catch((error) => {
      // An error happened.
      console.log(error)
    });
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar sx={{backgroundColor:"rgb(90, 191, 8)"}}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <img src={logo} width={35}/>
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Ssvpsra - Admin Panel
          </Typography>
          <Button onClick={logout} color="inherit">Logout</Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
