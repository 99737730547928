import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ListItem } from '@mui/material';

export default function FormDialog({open, setOpen, data, editData, addData, deleteData}) {
  const [title, setTitle]=React.useState(data.title)
  const [news, setNews]=React.useState(data.news)

  const handleClickOpen = () => {
    setOpen(true,{});
  };

  const handleClose = () => {
    setOpen(false);
    setNews("")
    setTitle("")
  };

  const submit=()=>{
    if((data.title!==title || data.news!=news)&&news!==""&&title!==""){
      if(data.title){
        editData(title, news)
      } else{
        addData(title, news)
      }
    }else{
      alert("Check Fields")
    }
  }

  React.useEffect(()=>{
    setNews(data.news)
  }, [data.news])
  
  React.useEffect(()=>{
    setTitle(data.title)
  }, [data.title])
  
  return (
    <div style={{display:"flex", justifyContent:"center"}}>
      <Button variant="outlined" sx={{marginTop:10}} onClick={handleClickOpen}>
        Add News
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{data.title?`Edit - ${data.title}`:"Add News"}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            value={title}
            onChange={(e)=>{setTitle(e.target.value)}}
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="News"
            type="text"
            fullWidth
            multiline
            value={news}
            onChange={(e)=>{setNews(e.target.value)}}
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {data.title?<Button onClick={deleteData}>Delete</Button>:""} 
          <Button onClick={submit}>{data.title?`Edit`:"Add"}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
