import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from './dialog'

function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

export default function AlignItemsList({db, user}) {
    const [dialog, setDialog]=React.useState(false)
    const [load, setLoad]=React.useState(false)
    const [news, setNews]=React.useState([])
    const [dialogData, setDialogData]=React.useState({})
    const getData= async ()=>{
      var news=[]
      const newsRef = db.collection("news");
      newsRef.get().then(querySnapshot=>{
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          var temp=doc.data()
          temp['id']=doc.id
          news.push(temp)
          console.log(doc.id, " => ", doc.data());
        });
        
        setNews(news)
      })
      
    }
    React.useEffect(()=>{
      getData()
    }, [db])

    const addData=(title, news)=>{
      if(!(user.write&&user.write.includes('news'))){
        return
      }
      db.collection("news").add({
        title:title,
        news:news
      })
      .then((docRef) => {
        alert("Added Successfully")
        getData()
        dialogToggle(false, {})

      })
      .catch((error) => {
        alert("Error Updating, Please try again")
          console.error("Error adding document: ", error);
      });

    }

    
    const editData=(title, news)=>{
      if(!(user.write&&user.write.includes('news'))){
        return
      }
      db.collection("news").doc(dialogData.id).set({
        title:title,
        news:news
      })
      .then((docRef) => {
        alert("Edited Successfully")
        getData()
        dialogToggle(false, {})

      })
      .catch((error) => {
        alert("Error Updating, Please try again")
      });
    }


    const deleteData=()=>{
      if(!(user.delete&&user.delete.includes('news'))){
        return
      }
      if(!dialogData.id){
        return
      }
      db.collection("news").doc(dialogData.id).delete().then(() => {
          getData()
          alert("News successfully deleted!");
          dialogToggle(false, {})
      }).catch((error) => {
          alert("Error removing News");
      });
    }

    const dialogToggle=(value, data, i)=>{
      if(value){
        setDialog(value)
        if(i){
          data['index']=i
        }
        setDialogData(data)

      }else{
        setDialog(value)
        setDialogData({})
      }
        
    }
  return (
    <List sx={{ width: '100%', maxWidth: 1500, bgcolor: 'background.paper' }}>
        {news.length>0&&news.map((item, i)=>{
           
            return(
                <ListItem alignItems="flex-start"
                  key={i}
                     secondaryAction={
                         <IconButton edge="end" aria-label="edit" onClick={()=>dialogToggle(true, item, i)}>
                         <EditIcon />
                       </IconButton>
                      }
                    >
                    <ListItemAvatar>
                    <Avatar alt={i+1} {...stringAvatar(item.title +` ${Math.random().toString()}`)} >{i+1}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary={item.title}
                        secondary={
                        <React.Fragment>
                        <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                        >
                            News - 
                        </Typography>
                        {item.news}
                        </React.Fragment>
                    }
                    />
                    <Divider variant="inset" component="li" />
                </ListItem>
            )
        })}
      
      <Dialog open={dialog} setOpen={dialogToggle} data={dialogData} editData={editData} addData={addData} deleteData={deleteData}/>
    </List>
  );
}
