import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { styled } from '@mui/material/styles';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import DeleteIcon from '@mui/icons-material/Delete';
import firebase from 'firebase';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';


const Input = styled('input')({
    display: 'none',
  });

  
export default function FormDialog({open, setOpen, data, editData, addData, db, getData, user}) {
  const [title, setTitle]=React.useState(data.title)
  const [news, setNews]=React.useState(data.news)
  const [upload, setUpload]=React.useState({})
  const [progress, setProgress]=React.useState(0)
  const [load, setLoad]=React.useState(false)

  const handleClickOpen = () => {
    setOpen(true,{});
  };

  const handleClose = () => {
    setOpen(false);
    setNews("")
    setTitle("")
  };

  const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    reader.readAsDataURL(file);
    })

  const submit=  (image)=>{
      
    if(!(user.write&&user.write.includes('gallary'))){
        return
      }
    var storageRef = firebase.storage().ref();
    var metadata = {
        contentType: 'image/jpeg',
      };
    //   fileToDataUri(file)
    //   .then(dataUri => {
    //     setDataUri(dataUri)
    //   })
    // let blob = await fetch(image).then(r => r.blob());
    var name=new Date().getTime().toString()+ ".jpg"
    var uploadTask = storageRef.child(name).put(image, metadata);
    console.log(image)
    setLoad(true)
    uploadTask.on('state_changed', 
        (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgress(progress)
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
        }, 
        (error) => {
            // Handle unsuccessful uploads
            alert("Error Uploading Image")
            setLoad(false)
        }, 
        () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                var imageData=data.data
                imageData.push(
                    {
                        row:2,
                        col:2,
                        image:downloadURL,
                        name:name
                    }
                )

                db.collection("gallary").doc(data.id).set({
                    data:imageData,
                    title:data.title,
                    image:data.image
                  })
                  .then((docRef) => {
                    alert("Uloaded Successfully")
                    setLoad(false)
                    getData()
                    setOpen(false, {})
            
                  })
                  .catch((error) => {
                    alert("Error Uploading, Please try again")
                    setLoad(false)
                  });
            });
        }
        );
  }

  const delImage=(item, i)=>{
      
    if(!(user.delete&&user.delete.includes('gallary'))){
        return
      }
    setLoad(true)
    var storageRef = firebase.storage().ref();
    var imageData=data.data
    imageData.splice(i, 1);
    db.collection("gallary").doc(data.id).set({
        data:imageData,
        title:data.title,
        image:data.image
      })
      .then((docRef) => {
        var desertRef = storageRef.child(item.name);
        // Delete the file
        desertRef.delete().then(() => {
            alert("Deleted Successfully")
            setLoad(false)
            getData()
            setOpen(false, {})
        })

      })
      .catch((error) => {
        setLoad(true)
        alert("Error Deleting, Please try again")
        setLoad(false)
      });
  }
  return (
    <div style={{display:"flex", justifyContent:"center"}}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{data.title}</DialogTitle>
        {load?<Box sx={{ display: 'flex', alignItems: 'center',padding:1 }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress variant="determinate" value={progress} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                progress
                )}%`}</Typography>
            </Box>
        </Box>:null}
        <DialogContent>
        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
            {data.data&&data.data.map((item, i) => (
                <ImageListItem key={item.image}>
                <img
                    src={`${item.image}`}
                    srcSet={`${item.image}`}
                    alt={item.title}
                    loading="lazy"
                />
                 <ImageListItemBar
                    actionIcon={
                    <IconButton
                    
                        disabled={!(user.delete&&user.delete.includes('gallary'))}
                        onClick={()=>delImage(item, i)}
                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                        aria-label={`info about ${item.title}`}
                    >
                        <DeleteIcon />
                    </IconButton>
                        }
                    />
                </ImageListItem>
            ))}
            </ImageList>
        </DialogContent>
        <DialogActions>
            <label htmlFor="icon-button-file">
                <Input accept="image/*" id="icon-button-file" type="file" onChange={(e)=>submit(e.target.files[0])}/>
                <IconButton color="primary" aria-label="upload picture" component="span"            
                    disabled={!(user.write&&user.write.includes('gallary'))}
                >
                <PhotoCamera />
                </IconButton>
            </label>
        </DialogActions>

      </Dialog>
    </div>
  );
}


const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];